import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "assets/svg/givento_logo_ 1.svg";
import { GiventoCandleStripe } from "assets/svg";
import { getUserData, setUserDate } from "API/user";
import { useParams, Link } from "react-router-dom";
import "./index.css";

const InvitePage = () => {
  const { id } = useParams();
  const decodedURLId = atob(`${id}`);
  const decodedArr = decodedURLId.split(" ");
  const decodedId = decodedArr.splice(0, 1)[0]
  const decodedName = decodedArr.join(' ')
  const [inviteStep, setInviteStep] = useState(1);
  const [invitedName, setInvitedName] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [showError, setShowError] = useState(false);
  const refDate = useRef<HTMLInputElement>(null);
  const refMonth = useRef<HTMLInputElement>(null);
  const refYear = useRef<HTMLInputElement>(null);

  useEffect(() => {
    try {
      getUserData(decodedId || "352")
        .then((r) => r.json())
        .then((resp) => {
          resp.data && setInvitedName(resp.data.name);
        });
    } catch (e) {
      console.log("Error: ", e);
    }
  }, [decodedId]);
  //decoding of cyrillic names
  const byteArray = new Uint8Array(decodedName.length);
  for (let i = 0; i < decodedName.length; i++) {
    byteArray[i] = decodedName.charCodeAt(i) & 0xff;
  }
  const decoder = new TextDecoder("utf-8");
  const decodedCyrillicName = decoder.decode(byteArray);

  const handleSubmit = () => {
    setShowError(false);
    const userDates = {
      userId: +(decodedId || 370),
      name: decodedCyrillicName,
      type: "birthday",
      date: {
        day: Number(birthDay),
        month: Number(birthMonth),
        year: birthYear !== "" ? Number(birthYear) : undefined,
      },
    };
    try {
      setUserDate(decodedId || "370", userDates)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.statusCode === 200 || resp.statusCode === 201) {
            setBirthDay("");
            setBirthMonth("");
            setBirthYear("");
            setInviteStep(2);
          } else {
            setShowError(true);
          }
        });
    } catch (e) {
      console.log("Error>>>", e);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === "refDate") {
      if (parseInt(value) > 3) {
        const ref = getRef(name);
        ref.current!.focus();
      }
    }
    if (name === "refMonth") {
      if (value.length >= 2 || parseInt(value) > 1) {
        const ref = getRef(name);
        ref.current!.focus();
      }
    }
  };

  const getRef = (name: string) => {
    switch (name) {
      case "refDate":
        return refMonth;
      case "refMonth":
        return refYear;
      default:
        return refDate;
    }
  };

  const validKeyForDate = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
  ];

  const handleSetBirthDay = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (Number(value) > 31) {
      e.preventDefault()
      return
    }
    setBirthDay(value)
  }

  const handleSetBirthMonth = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (Number(value) > 12) {
      e.preventDefault()
      return
    }
    setBirthMonth(value)
  }

  const handleSetBirthYear = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length > 4) {
      e.preventDefault()
      return
    }
    setBirthYear(value)
  }

  return (
    <div className="invitepage-container">
      <div className="invitepage-logo-container">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="greeting-form-container">
        {invitedName && (
          <>
            {inviteStep === 1 && (
              <div className="greeting-form">
                <div className="greeting-form-internal">
                  <h1 className="title">
                    Share your Birthday with {invitedName}
                  </h1>
                  <div className="inputs-bday">
                    <div className="input-wrapper">
                      <input
                        type="number"
                        id="userBDay"
                        name="refDate"
                        placeholder="  "
                        ref={refDate}
                        value={birthDay}
                        pattern="[0-9]*"
                        maxLength={2}
                        onChange={(e) => {
                          handleSetBirthDay(e);
                          handleChange(e);
                        }}
                        onKeyDown={(e) => {
                          if (!validKeyForDate.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="float-placeholder">DD</span>
                    </div>
                    <div className="input-wrapper">
                      <input
                        type="number"
                        name="refMonth"
                        placeholder="  "
                        ref={refMonth}
                        value={birthMonth}
                        pattern="[0-9]*"
                        maxLength={2}
                        onChange={(e) => {
                          handleSetBirthMonth(e);
                          handleChange(e);
                        }}
                        onKeyDown={(e) => {
                          if (!validKeyForDate.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="float-placeholder">MM</span>
                    </div>
                    <div className="input-wrapper">
                      <input
                        type="number"
                        name="refYear"
                        placeholder="  "
                        ref={refYear}
                        value={birthYear}
                        pattern="[0-9]*"
                        maxLength={4}
                        onChange={(e) => handleSetBirthYear(e)}
                        onKeyDown={(e) => {
                          if (!validKeyForDate.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="float-placeholder">YYYY</span>
                    </div>
                  </div>
                  <button className="submitBtn" onClick={handleSubmit}>
                    Send
                  </button>
                  {showError && (
                    <span className="error-message">
                      Please enter a valid date.
                    </span>
                  )}
                </div>
              </div>
            )}
            {inviteStep === 2 && (
              <div className="answer-container">
                <GiventoCandleStripe />
                <h4 className="answer-title">Thank You!</h4>
                <div className="answer-text">
                  <p>Your birthday has been shared with {invitedName}.</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <a
        href="https://apps.apple.com/us/app/birthday-countdown-reminder/id1640049062"
        target="_blank"
        rel="noreferrer"
      >
        <div className="invitepage-appstore-btn"></div>
      </a>
    </div>
  );
};

export default InvitePage;
