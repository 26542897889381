import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToAnchor = () => {
  const location = useLocation();
  const lastHash = useRef('');
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }
    const anchorEl = document.getElementById(lastHash.current)
    if (lastHash.current && anchorEl) {
      setTimeout(() => {
        anchorEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);
  return null;
}

export const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
}
