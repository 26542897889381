const BaseUrl = process.env.REACT_APP_DEV_BACKEND_URL

export const getUserData = async (userId: string) => {
  return await fetch(`${BaseUrl}/invites/${userId}`)
}

export const setUserDate = async (userId: string, userData: any) => {
  return await fetch(`${BaseUrl}/invites`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userData)
  })
}
