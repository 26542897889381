import Header from "Components/Header";
import Footer from "Components/Footer";
import { useEffect, useState } from "react";
import { AppStoreLogo, ArrowUpGray } from "assets/svg";
import { useParams } from "react-router-dom";
import { getPostcardData } from "API/postcard";
import "Styles/PostcardPage/index.css";

function PostcardPage() {
  const [senderName, setSenderName] = useState("Givento's user");
  const [coverImg, setCoverImg] = useState("");
  const [contentImg, setContentImg] = useState("");
  const [isPostcardOpen, setIsPostcardOpen] = useState(false);
  const [isOpenCardBlocked, setIsOpenCardBlocked] = useState(false);
  const { id } = useParams();
  const BaseUrl = process.env.REACT_APP_DEV_BACKEND_URL;

  const handlePostcardOpen = () => {
    if (isOpenCardBlocked) {
      return;
    }
    setIsPostcardOpen(!isPostcardOpen);
    setIsOpenCardBlocked(true);
    setTimeout(() => {
      setIsOpenCardBlocked(false);
    }, 2000);
  };

  useEffect(() => {
    try {
      getPostcardData(id || "90").then((resp) => {
        if (resp.statusCode === 200 || 201) {
          setSenderName(resp.data?.name);
          setCoverImg(resp.data?.coverLink);
          setContentImg(resp.data?.contentLink);
        }
        if (resp.statusCode !== 200 || 201) {
          console.log("Server response>>", resp.message);
        }
      });
    } catch (e) {
      console.log("Error: ", e);
    }
  }, [id]);

  return (
    <>
      <Header />
      <div className="startpage-container_postcard page-wrapper">
        <div style={{ textAlign: "center" }}>
          <h1>{senderName} sent you a card</h1>
        </div>
        <div className="postcard_wrapper">
          <div id="book">
            <div id="page5" className="page" onClick={handlePostcardOpen}>
              <div
                className="front"
                style={{ background: `url(${BaseUrl}/${contentImg})` }}
              ></div>
              <div className="back"></div>
              <label htmlFor="five"></label>
            </div>
            <input
              type="checkbox"
              id="one"
              checked={isPostcardOpen}
              onChange={() => {}}
            />
            <div id="page1" className="page" onClick={handlePostcardOpen}>
              <div
                className="front"
                style={{ background: `url(${BaseUrl}/${coverImg}` }}
              ></div>
              <div className="back" onClick={handlePostcardOpen}></div>
              <label htmlFor="one"></label>
            </div>
          </div>
          <div className="postcard_text">
            <div className="d-flex">
              {!isOpenCardBlocked && (
                <>
                  <ArrowUpGray />
                  <span
                    style={{ color: "rgba(0, 0, 0, 0.30)", margin: "0 8px" }}
                  >
                    {isPostcardOpen ? "Tap to Close" : "Tap to Open"}
                  </span>
                  <ArrowUpGray />
                </>
              )}
            </div>
            <div className="text-center" style={{ color: "#475467" }}>
              <p>Get the app to save this card to your collection</p>
            </div>
            <div className="appStore-btn">
              <button onClick={() => window.open("#")}>
                <AppStoreLogo />
              </button>
            </div>
            <div
              className="text-center"
              style={{ color: "#475467", marginTop: "6px" }}
            >
              <p>
                Givento is the app for celebrations. Keep track of friend's
                birthdays and create amazing gifts like this one
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
export default PostcardPage;
