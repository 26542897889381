import Header from "Components/Header";
import Footer from "Components/Footer";

function SupportPage() {
  return (
    <>
      <Header />
      <div className="flex-center justify-center page-wrapper">
        <div className="contact-us">
          <h1>Contact us</h1>
          <p>Our friendly team would love to hear from you.</p>
          <form id="feedback-form">
            <div className="flex-center names">
              <div className="form-inputs-wrap left">
                <label htmlFor="firstname">First name</label>
                <input type="text" id="firstname" placeholder="First name" />
              </div>
              <div className="form-inputs-wrap">
                <label htmlFor="secondname">Last name</label>
                <input type="text" id="secondname" placeholder="Last name" />
              </div>
            </div>
            <div className="form-inputs-wrap">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" placeholder="you@company.com" />
            </div>
            <div className="form-inputs-wrap">
              <label htmlFor="feedback_msg">Message</label>
              <textarea
                name="message"
                id="feedback_msg"
                placeholder="Leave us a message..."
                rows={10}
              ></textarea>
            </div>
            <div className="flex-center" style={{ marginTop: "20px" }}>
              <input type="checkbox" style={{ marginRight: "12px" }} />
              <span>
                You agree to our friendly{" "}
                <a href="privacy.html">Privacy Policy</a>.
              </span>
            </div>
            <button>Submit</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SupportPage;
