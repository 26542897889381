import StartPage from "Pages/StartPage";
import { Route, Routes } from "react-router-dom";
import SupportPage from "Pages/Support";
import PrivacyPage from "Pages/PrivacyPage";
import TermsPage from "Pages/Terms";
import InvitePage from "Pages/InvitePage";
import PostcardPage from "Pages/PostcardPage";

function App() {
  return (
    <Routes>
      <Route path='/' element={<StartPage/>} />
      <Route path='/support' element={<SupportPage/>} />
      <Route path='/terms' element={<TermsPage/>} />
      <Route path='/privacy' element={<PrivacyPage/>} />
      <Route path='/invites/:id' element={<InvitePage/>} />
      <Route path='/postcard/:id' element={<PostcardPage/>} />
    </Routes>
  );
}
export default App;
