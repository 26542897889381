import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Drawer } from "@mui/material";

function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) =>
  (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <header className="inner-padding page-wrapper">

      <div className="container-fluid header-container">
        <div>
          <Link to='/'>
            <img src="../assets/svg/top_logo.svg" alt="top logo"/>
          </Link>
        </div>
        <Button onClick={toggleDrawer(true)} className="burger-toggle-button">
          <span className='burgerButtonIcon'>|||</span>
        </Button>
        <Drawer
          anchor='right'
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}>
          <div className="navigation-collapsed" id="navLinksCollaps">
            <ul className="navbar-nav my-2 my-lg-0">
              <li className="nav-item">
                <div className="nav-item">
                  <Link to='/#features'>Features</Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-item">
                  <Link to='/#gifting-calendar'>Benefits</Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-item">
                  <Link to="/support">Contact us</Link>
                </div>
              </li>
              <li className="nav-item" style={{paddingRight: 0}}>
                <div className="nav-item">
                  <Link to='/#download'>
                    <div className="nav-item marked">Get the App</div>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </Drawer>
        <div className="navigation" id="navLinksCollaps">
          <ul className="navbar-nav my-2 my-lg-0">
            <li className="nav-item">
              <div className="nav-item">
                <Link to='/#features'>Features</Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item">
                <Link to='/#gifting-calendar'>Benefits</Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item">
                <Link to="/support">Contact us</Link>
              </div>
            </li>
            <li className="nav-item dropdown" style={{paddingRight: 0}}>
              <Link to='/#download'>
                <div className="nav-item marked">Get the App</div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
