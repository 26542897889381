import { ScrollToAnchor } from 'utils/helpers';
import Header from "Components/Header";
import Footer from "Components/Footer";

const StartPage = () => {
  ScrollToAnchor();
  return (
    <>
      <Header />
      <div className="main-screen page-wrapper">
        <div className="main-item flex-center">
          <div>
            <h1>
              Never Forget <br /> a Birthday{" "}
              <span style={{ color: "#2BD6BC" }}>or Event Again!</span>
            </h1>
            <p>
              Stay organized and never miss an important date with our
              easy-to-use reminder app.
            </p>
            <div className="hide-button">
              <a className="scrollto" href="#features">
                <div className="explore-features-btn flex-center">
                  Explore Features
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="main-item main-right flex-center">
          <img
            src="./assets/svg/Givento_files/main_screen_pic.png"
            alt="main screen"
          />
        </div>
      </div>
      <div id="features" className="features-block page-wrapper">
        <div className="features-title">
          <h1>Features title</h1>
        </div>
        <div className="flex-center items-block">
          <div className="feature-item">
            <div className="item-img-container flex-center justify-center">
              <img src="./assets/svg/feature-icon-1.svg" alt="feature icon" />
            </div>
            <div className="item-text">
              <h1>Keep on track</h1>
              <p>Get push notification reminders for important dates.</p>
            </div>
          </div>
          <div className="feature-item">
            <div className="item-img-container flex-center justify-center">
              <img src="./assets/svg/feature-icon-2.svg" alt="feature icon" />
            </div>
            <div className="item-text">
              <h1>Time and Ease</h1>
              <p>Customize your reminders to fit your needs.</p>
            </div>
          </div>
          <div className="feature-item">
            <div className="item-img-container flex-center justify-center">
              <img src="./assets/svg/feature-icon-3.svg" alt="feature icon" />
            </div>
            <div className="item-text">
              <h1>Peace of mind</h1>
              <p>Simple and intuitive interface to manage events.</p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="gifting-calendar"
        className="gifting-calendar purple-bg page-wrapper"
      >
        <div className="gifting-calendar-item justify-center calendar-item-padding">
          <div className="benefit-text">
            <h1 className="h1-titles-48-600">Gifting Calendar</h1>
            <p style={{ color: "#58586F" }}>
              Never forget an important birthday or event again with push
              notification reminders sent to your phone a week before, a day
              before, and on the same day of the event.
            </p>
          </div>
        </div>
        <div className="gifting-calendar-item flex-center justify-center">
          <img
            src="./assets/images/benefits-image-1.png"
            alt="calendar phone item"
          />
        </div>
      </div>
      <div className="gifting-calendar benefit-2 lightGreen-bg page-wrapper">
        <div className="gifting-calendar-item benefit-2-pb flex-center justify-center">
          <img
            src="./assets/images/benefits-image-2.png"
            alt="calendar phone item"
            className="web-image"
          />
          <img
            src="./assets/images/benefits-image-2-reverse.png"
            alt="calendar phone item"
            className="mob-image"
          />
        </div>
        <div className="gifting-calendar-item benefit-item-2 justify-center">
          <div className="benefit-text">
            <h1 className="h1-titles-48-600">
              Set up to receive <br /> notifications
            </h1>
            <p style={{ color: "#58586F", marginBottom: "35px" }}>
              Customize your reminders with options such as choosing to receive
              notifications only on weekdays or selecting the time of day you
              receive notifications.
            </p>
          </div>
        </div>
      </div>
      <div className="gifting-calendar peach-bg page-wrapper">
        <div className="gifting-calendar-item justify-center">
          <div className="benefit-text">
            <h1 className="h1-titles-48-600">Send and track a gift</h1>
            <p style={{ color: "#58586F", marginBottom: "35px" }}>
              Our app has a user-friendly interface that makes it easy to add,
              edit, and delete events. Quickly view all of your upcoming events
              in one place and set personalized reminders for each one with just
              a few taps.
            </p>
          </div>
        </div>
        <div className="gifting-calendar-item flex-center justify-center">
          <img
            src="./assets/images/benefits-image-3.png"
            alt="calendar phone item"
          />
        </div>
      </div>
      <div
        id="download"
        className="gifting-calendar strange-bg page-wrapper"
        style={{ paddingTop: 0 }}
      >
        <div
          className="gifting-calendar-item flex-center justify-center"
          style={{ paddingTop: "52px" }}
        >
          <img
            src="./assets/images/download-app.png"
            alt="calendar phone item"
          />
        </div>
        <div className="gifting-calendar-item justify-center">
          <div className="download-benefit-item">
            <h1 className="h1-titles-48-600">
              Our Mobile Apps Are Coming Soon
            </h1>
            <p style={{ color: "#58586F" }}>
              Don't miss out on another birthday or special event ever again!
              Download our amazing Birthday and Event Reminder App today to make
              sure you never forget a date again. It's totally free – so don't
              wait any longer to get it today!
            </p>
          </div>
          <div className="apps-block">
            <div>
              <a href="https://apps.apple.com/us/app/birthday-countdown-reminder/id1640049062">
                <div className="dload-btn appStore"></div>
              </a>
            </div>
            {/*<div>*/}
            {/*  <a href="#">*/}
            {/*    <div className="dload-btn googlePlay">*/}
            {/*    </div>*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default StartPage;
