import { Link } from "react-router-dom";

function Footer() {
  const date = new Date()
  const currentYear = date.getFullYear()

  return (
    <footer className="inner-padding footer page-wrapper">
      <div className="flex-center justify-between">
        <div>
          <Link to="/">
            <img src="../assets/svg/top_logo.svg" alt="top logo"/>
          </Link>
        </div>
        <div className="nav-links flex-center">
          <div className="nav-item">
            <Link to="/support">Contact Us</Link>
          </div>
          <div className="nav-item">
            <Link to="/privacy">Privacy policy</Link>
          </div>
          <div className="nav-item">
            <Link to="/terms">Terms & Conditions</Link>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div style={{padding: "40px 0", color: "#728095"}}
            className="flex-center justify-between footer-contacts-info">
        <div className="copymark-block">
          &copy; {currentYear} Givento Gifts - Birthday and event
          reminder LTD
        </div>
        <div className="flex-center contact-data">
          <div>
            <span style={{fontWeight: 500}}>Email:</span> apps@givento.io
          </div>
          <div className="contacts">
            <span style={{fontWeight: 500}}>Office:</span> 43/8, GARDNER'S CRESCENT EH3 8DG UK
          </div>
          <div>
            <span style={{fontWeight: 500}}>Phone:</span> +447377122457
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
